import { Flex, Heading, Text } from "@chakra-ui/core";
import { Link } from "gatsby";
import React from "react";
import PostLayout from "../components/postLayout";
import SEO from "../components/seo";
import { css } from "@emotion/core";

const NotFound = () => {
  return (
    <PostLayout>
      <SEO title="Page Not Found" />
      <Flex flexDirection={"column"}>
        <Flex flexDirection={"column"} alignItems="center" mt={"2rem"}>
          <Heading as="h1" textAlign="center">
            404: Page Not Found
          </Heading>
        </Flex>
        <Flex flexDirection="column">
          <Text mt={"2rem"}>The page you are looking for does not exist.</Text>
          <Text mt="1rem">
            We recently updated our website and old links were changed in the
            process. If you are looking for a particular post, all the posts can
            be found{" "}
            <span
              style={{ color: "#ff9048" }}
              css={css`
                &:hover {
                  text-decoration: underline;
                }
              `}
            >
              <Link to="/posts">here</Link>
            </span>
            .
          </Text>
        </Flex>
      </Flex>
    </PostLayout>
  );
};

export default NotFound;
